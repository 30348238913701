import plFlag from '../../assets/flags/pl.svg';
import gbFlag from '../../assets/flags/gb.svg';
import itFlag from '../../assets/flags/it.svg';
import cnFlag from '../../assets/flags/cn.svg';

const Countries = {
    'PL': {
        flag: plFlag,
        nameKey: 'APP_LANG_PL_LABEL'
    },
    'GB': {
        flag: gbFlag,
        nameKey: 'APP_LANG_GB_LABEL'
    },
    'IT': {
        flag: itFlag,
        nameKey: 'APP_LANG_IT_LABEL'
    },
    'CN': {
        flag: cnFlag,
        nameKey: 'APP_LANG_CN_LABEL'
    }
}

export default Countries;