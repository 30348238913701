const RoutesPath = {
    HOME: '/',
    ROLES: '/roles',
    SEND_TO_ALL: '/send-to-all',
    LOGS: '/logs',
    API_RESULTS: '/api-results',
    APP_CONFIG: '/app-config', // /:type
    APP_CONFIG_MAIN: '/app-config/main',
    APP_CONFIG_PASSWORD: '/app-config/password',
    MAP_USERS_LOCATION: '/map-users-location',
    USER_LIST: '/user-list',
    AUTH_HISTORY: '/auth-history',
    SEND_EMAIL_HISTORY: '/send-email-history',
    ACTIVE_CONTACTS: '/active-contacts',
    CHAT: '/chat', // /:userId
}

export default RoutesPath;