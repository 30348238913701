import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {HashRouter} from "react-router-dom";
import store from './redux/store';

const container = document.getElementById('root');
const root = createRoot(container);

// przez <React.StrictMode> wszystko renderuje się 2 razy

const main = (
    <Provider store={store}>
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>
)

root.render(main);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();